<mat-card class="login-register-card">
  <mat-card-header>
    <mat-card-title>Register</mat-card-title>
    <mat-card-subtitle>
      <a routerLink="/login">Login Instead?</a>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="loginRegisterForm">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" id="name" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" id="email" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input
          matInput
          type="password"
          formControlName="password"
          id="password"
        />
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="this.handleClick()">REGISTER</button>
  </mat-card-actions>
</mat-card>
