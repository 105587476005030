<mat-toolbar color="primary" class="mat-elevation-z6">
  <div id="page-actions-container">
    <ng-container *ngIf="selectedViewService.selectedViewIsNoteView$ | async">
      <button
        mat-icon-button
        class="workspace-button"
        (click)="this.selectedViewService.leaveNoteView()"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
    </ng-container>
    <ng-container>
      <button
        *ngIf="mobileService.isMobile$ | async"
        mat-icon-button
        (click)="mobileService.toggleCharacterListSidenav()"
      >
        <mat-icon>menu</mat-icon>
      </button>
    </ng-container>
  </div>

  <div id="app-name-container">
    <h1>Vamp Notebook</h1>
  </div>

  <div id="user-actions-container">
    <div *ngIf="this.auth.isSignedIn$ | async">
      <button mat-button (click)="this.auth.signOut()">Sign Out</button>
      <!-- <button
        mat-icon-button
        aria-label="User Account"
        [routerLink]="['/users']"
      >
        <mat-icon>account_circle</mat-icon>
      </button> -->
    </div>
    <div *ngIf="(this.auth.isSignedIn$ | async) === null">
      <a mat-button routerLink="/login"> Sign In </a>
    </div>

    <button
      *ngIf="mobileService.isMobile$ | async"
      mat-icon-button
      (click)="mobileService.toggleNoteListSidenav()"
    >
      <mat-icon>list</mat-icon>
    </button>
  </div>
</mat-toolbar>
