<mat-card class="login-register-card">
  <mat-card-header>
    <mat-card-title>Login</mat-card-title>
    <!-- <mat-card-subtitle>
      <a routerLink="/register" disabled>Register Instead?</a>
    </mat-card-subtitle> -->
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="loginRegisterForm">
      <mat-form-field appearance="outline">
        <mat-label>email</mat-label>
        <input matInput formControlName="email" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="password" />
      </mat-form-field>

      <mat-checkbox formControlName="rememberMe">Remember Me</mat-checkbox>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="this.handleClick()">LOGIN</button>
  </mat-card-actions>
</mat-card>
